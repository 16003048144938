import React from 'react';
import './Home.css';
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div>
      <Container className="home-content">
        <Container className="imgRow">
          <Row className="mission-section">
            <Col md={6} className="d-flex align-items-center">
              <Card className="imgCard">
                <Card.Body>
                  <Card.Title className="fw-bold">Our Mission</Card.Title>
                  <Card.Text>
                    At the San Diego Bay Pilots Association, our mission is to leverage our extensive ship handling
                    expertise and intimate local knowledge to ensure the safety and security of San Diego’s port facilities
                    and infrastructure. We are dedicated to protecting the people of San Diego, preserving the marine
                    environment, and upholding our national security. Through precision navigation and vigilant
                    stewardship, we are committed to maintaining the integrity and operational excellence of our harbor, fostering
                    a safe and thriving maritime community.
                  </Card.Text>
                  <Link to="/about">
                    <Button variant="primary" className='custom-button'>Learn More About Us</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className="imgCol d-flex align-items-center" md={6}>
              <Image src="./assets/photos/IMG_8317.JPG" fluid />
            </Col>
          </Row>
          <Row className="port-section">
            <Col className="imgCol d-flex align-items-center" md={6}>
              <Image src="./assets/photos/IMG_8314.JPG" fluid />
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <Card className="imgCard">
                <Card.Body>
                  <Card.Title className="fw-bold">Our Port</Card.Title>
                  <Card.Text>
                    The Port of San Diego is a dynamic maritime hub that plays a pivotal role in the region’s economy,
                    accommodating a wide range of commercial, recreational, and military activities. Key locations within
                    the port are essential for its operations and services, contributing to its status as a premier maritime
                    destination on the West Coast.
                  </Card.Text>
                  <Link to="/port">
                    <Button variant="primary" className='custom-button'>Learn More About Our Port</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default Home;