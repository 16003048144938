// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css'; // You can style the footer using this CSS file

function Footer() {
  return (
    <footer className="footer text-white mt-5">
      <Container>
        <Row>
          <Col className="">
            <strong><u>Dispatch</u></strong>
            <p><strong>Mobile:</strong> +1 (619) 233-3096<br />
              <strong>Email:</strong> Pilots@SDPilotage.com</p>
            <strong><u>Office</u></strong>
            <p><strong>Mobile:</strong> +1 (619) 495-0825<br />
              <strong>Email:</strong> Office@SDPilotage.com</p>
          </Col>
          <Col>
            <img
              src="/assets/logo/sdbaypilots_goldoutline.png"
              width="150"
              height="150"
              className="d-inline-block align-top"
              alt="San Diego Bay Pilots Association"
            />
            <p><em>Serving San Diego Since 1992</em></p>
          </Col>
          <Col className="">
            <p><strong><u>Mailing Address</u></strong> <br />
              P.O. Box 182137 <br />
              Coronado, CA 92178</p><br />
            <p>&copy; {new Date().getFullYear()} San Diego Bay Pilots Association. <br />All Rights Reserved.</p>
          </Col>
        </Row>

      </Container>
    </footer>
  );
}

export default Footer;
