// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reCaptcha {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.custom-button {
    background-color: #282763;
    /* Custom background color */
    border-color: #282763;
    /* Custom border color */
    color: white;
    /* Custom text color */
}

.custom-button:hover {
    background-color: #282763;
    /* Hover effect */
    border-color: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactForm/ContactForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;IAC5B,qBAAqB;IACrB,wBAAwB;IACxB,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,qBAAqB;AACzB","sourcesContent":[".reCaptcha {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 15px;\n}\n\n.custom-button {\n    background-color: #282763;\n    /* Custom background color */\n    border-color: #282763;\n    /* Custom border color */\n    color: white;\n    /* Custom text color */\n}\n\n.custom-button:hover {\n    background-color: #282763;\n    /* Hover effect */\n    border-color: #ff0000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
