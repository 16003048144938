// src/pages/MapWithDetails.js
import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MapComponent from '../../components/MapComponent/MapComponent';

function Port() {
    const [selectedLandmark, setSelectedLandmark] = useState(null);

    const handleMarkerClick = (landmark) => {
        setSelectedLandmark(landmark);
    };

    return (
        <Container className="mt-5">
            <Row>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title className="fw-bold">Our Port</Card.Title>
                        <Card.Text>
                            <p>
                                The Port of San Diego’s diverse and strategically located facilities underscore its importance as a
                                hub for maritime activities, supporting trade, tourism, defense, and local industry. Each location within
                                the port plays a unique role in maintaining its operational excellence and economic vitality.
                            </p>
                            <Link to="https://www.portofsandiego.org/" target="_blank">
                                Port of San Diego Website
                            </Link>
                            <p>
                                <strong>Click on a marker below to learn more about the Marine Terminals and Shipyards that we support.</strong>
                            </p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Row>
            <Row>
                <Col>
                    <MapComponent onMarkerClick={handleMarkerClick} />
                </Col>
            </Row>
            {selectedLandmark && (
                <Row className="mt-4">
                    <Col>
                        <h3>{selectedLandmark.name}</h3>
                        <p>{selectedLandmark.description}</p>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default Port;