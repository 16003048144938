import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, FloatingLabel, Card } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactForm.css'

function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        to_email: '',
        attachment: null
    });

    const [captchaValue, setCaptchaValue] = useState(null);

    const handleCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setCaptchaValue(value);
    };

    useEffect(() => {
        setFormData((prevData) => ({ ...prevData, to_email: prevData.email }));
    }, [formData.email]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            attachment: e.target.files[0]
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send form data to a server)
        // EmailJS service details
        const serviceID = 'service_eqd5rfl';
        const templateID = 'template_ryilpo1';
        const publicKey = 'Av3A5d80uJQzQcq7k';

        // // Prepare form data
        // const form = new FormData();
        // form.append('name', formData.name);
        // form.append('email', formData.email);
        // form.append('to_email', formData.email);
        // form.append('phone', formData.phone);
        // form.append('message', formData.message);
        // form.append('attachment', formData.attachment);

        emailjs.init({
            publicKey: publicKey
        });

        emailjs.sendForm(serviceID, templateID, e.target, publicKey)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Your message has been sent!');
            }, (error) => {
                console.log('FAILED...', error);
                alert('Failed to send the message. Please try again.');
            });

    };

    return (

        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formName" className="mb-3">
                                    <FloatingLabel
                                        controlId="floatingName"
                                        label="Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Enter your name"
                                        />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group controlId="formEmail" className="mb-3">
                                    <FloatingLabel
                                        controlId="floatingEmail"
                                        label="Email"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Enter your email"
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Control
                                    type="hidden"
                                    name="to_email"
                                    value={formData.to_email}
                                />

                                <Form.Group controlId="formPhone" className="mb-3">
                                    <FloatingLabel
                                        controlId="floatingPhone"
                                        label="Phone"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            placeholder="Enter your phone number"
                                        />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group controlId="formMessage" className="mb-3">
                                    <FloatingLabel
                                        controlId="floatingMessage"
                                        label="Message"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            placeholder="Enter your message"
                                        />
                                    </FloatingLabel>
                                </Form.Group>

                                {/* <Form.Group controlId="formAttachment">
                                    <Form.Label>Attachment</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="attachment"
                                        onChange={handleFileChange}
                                    />
                                </Form.Group> */}
                                <Form.Group className='reCaptcha'>
                                    <ReCAPTCHA
                                        sitekey="6Lfgt7YqAAAAACLKtJiiZc2w-iMVPnUX27FmRr4Z"
                                        onChange={handleCaptchaChange}
                                    />
                                </Form.Group>
                                <Button variant="primary" className='custom-button' type="submit" disabled={!captchaValue}>
                                    Submit
                                </Button>
                            </Form>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactForm;
