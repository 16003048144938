// src/Navbar.js
import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavBar.css';

function NavigationBar() {
  // State to control Navbar collapse
  const [expanded, setExpanded] = useState(false);

  // Toggle Navbar on hamburger click
  const handleToggle = () => setExpanded(!expanded);

  // Collapse Navbar when a link is clicked
  const handleClose = () => setExpanded(false);

  return (
    <Navbar variant="dark" expand="lg" expanded={expanded}>
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src="/assets/logo/sdbaypilots_goldoutline.png"
            width="100"
            height="100"
            className="d-inline-block align-top"
            alt="San Diego Bay Pilots Association"
          />
          <span className="ms-3 text-white fs-4 fw-bold align-middle d-none d-md-block">
            The San Diego Bay Pilots Association
          </span>
          <span className="ms-3 text-white fs-5 fw-bold align-middle d-block d-md-none">
            San Diego Bay Pilots
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/about">
              <Nav.Link onClick={handleClose}>About Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/port">
              <Nav.Link onClick={handleClose}>Our Port</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/gallery">
              <Nav.Link onClick={handleClose}>Gallery</Nav.Link>
            </LinkContainer>
            {/* <LinkContainer to="/faq">
              <Nav.Link onClick={handleClose}>FAQs</Nav.Link>
            </LinkContainer> */}
            {/* <LinkContainer to="/news">
              <Nav.Link onClick={handleClose}>News</Nav.Link>
            </LinkContainer> */}
            <LinkContainer to="/contact">
              <Nav.Link onClick={handleClose}>Contact</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar